@import '../../assets/styles/helpers';


.container {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    padding-bottom: 96px;

    .wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 48px;
        margin-bottom: 52px;
        @include media-max('sm') {
            flex-direction: column;
            margin-top: 32px;
            margin-bottom: 0;
        }

        .tabsContainer {
            max-width: 356px;
            width: 100%;
            background: color('light-primary-light-gray-100');
            border: 1px solid color('light-primary-light-gray-100');
            border-radius: 1000px;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            @include media-max('sm') {
                display: none;
            }

            .tabBtn {
                font-weight: 500;
                font-size: 14px;
                list-style: none;
                line-height: 16px;
                color: color('light-primary-gray-500');
                margin-right: 4px;
                cursor: pointer;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 14px;

                &:hover {
                    background: color('white');
                    color: color('light-primary-light-gray-700');
                    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
                    border-radius: 1000px;
                }

                &.active {
                    background: color('white');
                    color: color('light-primary-light-gray-700');
                    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
                    border-radius: 1000px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .mobileTabContainer {
            display: none;
            align-items: center;
            justify-content: space-between;

            li {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: color('light-primary-gray-500');
                padding-bottom: 11px;
                margin-right: 24px;
                cursor: pointer;
                position: relative;
                @include media-max('md') {
                    font-size: 14px;
                }

                :last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: color('light-primary-light-gray-700');

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -1px;
                        height: 2px;
                        background-color: color('light-primary-light-gray-700');
                    }
                }
            }

            .active {
                font-weight: 500;
                position: relative;
                color: color('light-primary-light-gray-700');

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 2px;
                    background-color: color('light-primary-light-gray-700');
                }
            }

            @include media-max('sm') {
                display: flex;
            }
        }

        .buttonsContainer {
            display: flex;
            align-items: center;
            @include media-max('sm') {
                margin-bottom: 32px;
            }

            .downloadBtn {
                width: 162px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid color('light-primary-light-gray-300');
                border-radius: 1000px;
                margin-right: 12px;

                svg {
                    margin-right: 10px;
                }

                button {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: color('light-primary-light-gray-700');
                }
            }
        }

        .titles {
            @include media-max('sm') {
                margin-bottom: 16px;
            }

            .titleSubtext {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: color('light-primary-gray-500');
            }

            .mainTitle {
                font-weight: 500;
                font-size: 30px;
                line-height: 35px;
                margin-bottom: 4px;
                color: color('dark-primary-gray-900');
            }
        }
    }

    .calendarContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        .calendarWrapper {
            max-width: 260px;
            padding: 11px 18px;
            display: flex;
            align-items: center;
            background: color('white');
            border: 1px solid color('light-primary-light-gray-300');
            box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 1000px;
            width: 100%;
            height: 40px;
        }
    }

    .arrowsContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            cursor: pointer;
        }

        :first-child {
            margin-right: 10px;
        }
    }

    .mobileDivider {
        display: none;
        margin-bottom: 36px;
        width: 100%;
        @include media-max('sm') {
            display: block;
        }
    }

    .chartHeader {
        width: 100%;
        height: 43px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid color('light-primary-light-gray-200');
        margin-bottom: 21px;

        h2 {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: color('dark-primary-gray-900');
        }
    }

    .space {
        margin-bottom: 32px;
    }

    .chartContainer {
        .chartHeader {
            width: 100%;
            height: 43px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid color('light-primary-light-gray-200');
            margin-bottom: 21px;

            h2 {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: color('dark-primary-gray-900');
            }
        }

        .chartWrapper {
            display: flex;
            // align-items: center;
            @include media-max('sm') {
                flex-direction: column;
            }

            .chartInfo1 {
                // margin-top: 20px;
                max-height: 390px;
                min-height: 280px;
                margin-right: 40px;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // justify-content: space-between;
                @include media-max('sm') {
                    margin-right: 0;
                }

                .singUpInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('light-primary-yellow-600');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .buildsSuccessfulInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('buildsSuccessful');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .buildsFailedInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('buildsFailed');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .buildsProcessingInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color(buildsProcessing);
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .unloggedBuildContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('unloggedBuild');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .emailCompletionInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('light-primary-yellow-600');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .averageBuildTimesInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('light-primary-yellow-600');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .createdAtInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('gray');
                            display: inline-block;
                            padding-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('light-success-6');
                        }
                    }
                }
            }

            .chartInfo2 {
                // margin-top: 20px;
                max-height: 390px;
                min-height: 280px;
                margin-right: 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // justify-content: center;
                @include media-max('sm') {
                    margin-right: 0;
                }
            }

            .singUpInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('light-primary-yellow-600');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .emailCompletionInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('gray');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .averageBuildTimesInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('black');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .totalBuildInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('midnight-blue');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('light-success-6');
                    }
                }
            }

            .createdAtInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('gray');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('light-success-6');
                    }
                }
            }

            .sendsInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('light-success-500');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('light-success-500');
                    }
                }
            }

            .rejectsInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('error-700');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .deliveriesInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('midnight-blue');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('midnight-blue');
                    }
                }
            }

            .complaintsInformationContent {
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('error-400');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('light-success-6');
                    }
                }
            }
        }
        .buildChartWrapper{
            display: flex;
            // align-items: center;
            @include media-max('sm') {
                flex-direction: column;
            }

            .chartInfo1 {
                // margin-top: 20px;
                max-height: 390px;
                min-height: 280px;
                margin-right: 40px;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // justify-content: space-between;
                @include media-max('sm') {
                    margin-right: 0;
                }

                .singUpInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('light-primary-yellow-600');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .buildsSuccessfulInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('buildsSuccessful');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .buildsFailedInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('buildsFailed');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .buildsProcessingInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color(buildsProcessing);
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .unloggedBuildContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('unloggedBuild');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .emailCompletionInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('light-primary-yellow-600');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .averageBuildTimesInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('light-primary-yellow-600');
                            display: inline-block;
                            margin-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('error-6');
                        }
                    }
                }

                .createdAtInformationContent {
                    .info {
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: color('black');
                        }

                        .circle {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: color('gray');
                            display: inline-block;
                            padding-right: 8px;
                        }
                    }

                    .number {
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                        color: #1d2129;
                        margin-bottom: 8px;
                    }

                    .percent {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 4px;
                        }

                        .percentageNumber {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: color('light-success-6');
                        }
                    }
                }
            }

            .chartInfo2 {
                // margin-top: 20px;
                max-height: 390px;
                min-height: 280px;
                margin-right: 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // justify-content: center;
                @include media-max('sm') {
                    margin-right: 0;
                }
            }

            .buildsSuccessfulInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('buildsSuccessful');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .buildsFailedInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('buildsFailed');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .buildsProcessingInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('buildsProcessing');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .unloggedBuildContent {
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('unloggedBuild');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .emailCompletionInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('gray');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .averageBuildTimesInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('black');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('error-6');
                    }
                }
            }

            .totalBuildInformationContent {
                padding-bottom: 12px;
                .info {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: color('black');
                    }

                    .circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: color('midnight-blue');
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                .number {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: #1d2129;
                    margin-bottom: 8px;
                }

                .percent {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 4px;
                    }

                    .percentageNumber {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: color('light-success-6');
                    }
                }
            }
        }

        .charts {
            min-height: 380px;
            height: 390;
            width: 100%;
            @include media-max('md') {
                width: 343px;
            }
             .chart {
            min-height: 380px;
            height: 390;
            width: 100%;
            @include media-max('md') {
                width: 343px;
            }
        }
        }
    }
}
