@import "./helpers";

body {
  font-family: $workSans;
  background-color: color('light-primary-gray-25');
}

img {
  max-width: 100%;
}

:root {
  min-height: 100vh;
  width: 100%;
}

