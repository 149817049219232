@import "../../assets/styles/helpers";

.container {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  padding-bottom: 96px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    @include media-max('sm') {
      flex-direction: column;
      align-items: flex-start;
    }

    .mainTitle {
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: color('dark-primary-gray-900');
      @include media-max('sm') {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .mobileTitleSubtext {
      display: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: color('light-primary-gray-500');
      margin-bottom: 24px;
      @include media-max('sm') {
        display: block;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      .downloadBtn {
        width: 162px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid color('light-primary-light-gray-300');
        border-radius: 1000px;
        margin-right: 12px;
        @media only screen and (max-width: 370px) {
          width: 142px;
          margin-right: 8px;
        }

        svg {
          margin-right: 10px;
        }

        button {
          font-weight: 600;
          font-size: 14px;
          display: flex;
          align-items: center;
          line-height: 16px;
          color: color('light-primary-light-gray-700');
          @media only screen and (max-width: 370px) {
            font-size: 12px;
          }
        }
      }

      .uploadBtn {
        width: 162px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid color('black');
        border-radius: 1000px;
        margin-right: 12px;

        button {
          font-weight: 600;
          font-size: 14px;
          display: flex;
          align-items: center;
          line-height: 16px;
          color: color('black');
          @media only screen and (max-width: 370px) {
            font-size: 12px;
          }
        }
      }

      .addBtn {
        width: 154px;
        height: 40px;
        @media only screen and (max-width: 370px) {
          width: 142px;
        }

        button {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: color('white');
          @media only screen and (max-width: 370px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .navContainer {
    margin-top: 33px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-max('sm') {
        align-items: flex-start;
      }

      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: color('light-primary-light-gray-700');
        padding-bottom: 11px;
        margin-right: 24px;
        cursor: pointer;
        position: relative;

        @include media-max('md') {
          font-size: 14px;
        }
        @media only screen and (max-width: 350px) {
          margin-right: 15px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          height: 2px;
          background-color: color('light-primary-light-gray-700');
        }

        :last-child {
          margin-right: 0;
        }

        &:hover {
          color: color('light-primary-light-gray-700');

          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 2px;
            background-color: color('light-primary-light-gray-700');
          }
        }
      }
    }
  }

  .titleSubtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: color('light-primary-gray-500');
    margin-bottom: 24px;
    @include media-max('sm') {
      display: none;
    }
  }

  .dataCalendar {
    width: 100%;
    display: flex;
    margin-top: 32px;
    align-items: center;
    justify-content: space-between;
    @include media-max('lg') {
      flex-direction: column;
    }

    .searchInputWrapper {
      max-width: 400px;
      width: 100%;
      height: 44px;
      position: relative;


      @media only screen and (max-width: 1118px) {
        max-width: 350px;
      }
      @media only screen and (max-width: 1080px) {
        max-width: 300px;
      }
      @include media-max('lg') {
        max-width: unset;
        margin-bottom: 16px;
      }

      .searchIcon {
        position: absolute;
        top: 13px;
        left: 13px;
      }

      input {
        width: 100%;
        height: 100%;
        background: color('white');
        border: 1px solid color('light-primary-light-gray-300');
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 1000px;
        outline: none;
        padding-left: 42px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: color('light-primary-gray-500');
        @include media-max('sm') {
          border-radius: 8px;
        }

        &::placeholder {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: color('light-primary-gray-500');
        }

        &:focus {
          border: 1px solid color('light-primary-yellow-300');
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FAE3AA;
        }
      }

    }

    .calendarWrapper {

      .dataPickers {
        display: flex;
        max-width: 243px;
        margin-right: 12px;
        width: 100%;
        padding: 11px 18px;
        height: 40px;
        align-items: center;
        background: color('white');
        border: 1px solid color('light-primary-light-gray-300');
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 1000px;
        @media only screen and (max-width: 370px) {
          max-width: 180px;
        }


        input {
          cursor: pointer;
          border: none;
          width: 100%;
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #525453;
          display: flex;
          flex-direction: row-reverse;

          &:focus {
            outline: none;
            border: none;
          }

        }

      }

      .buttonsWrapper {
        width: 100%;
        display: flex;
        align-items: center;

        .filterBtn {
          max-width: 105px;
          width: 100%;
          height: 40px;
          border-radius: 1000px;
          margin-right: 12px;
          border: 1px solid color('light-primary-light-gray-300');

          button {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            background: color('white');
            color: color('light-primary-light-gray-700');

            svg {
              @include media-max('sm') {
                margin-right: 0;
              }
            }
          }
        }

        .sortingBtn {
          max-width: 105px;
          width: 100%;
          height: 40px;
          border-radius: 1000px;
          border: 1px solid color('light-primary-light-gray-300');

          button {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            background: color('white');
            color: color('light-primary-light-gray-700');

            svg {
              @include media-max('sm') {
                margin-right: 0;
              }
            }
          }
        }

      }
    }

  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: color('white');
    width: 100%;
    height: 61px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 20px 24px 19px;
    border: 1px solid color('light-primary-light-gray-200');
    margin-top: 24px;

    svg {
      cursor: pointer;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: color('dark-primary-gray-900');
    }
  }

  thead {
    tr {
      .columnHeader {
        display: flex;
        align-items: center;

        .svg {
          margin-left: 4px;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .arrowSvg {
          svg {
            transform: rotateZ(-180deg);
          }
        }
      }

      th {
        background: color('light-primary-gray-50');
        padding: 14px 24px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        color: color('light-primary-gray-500');
      }
    }
  }

  tbody {

    tr {
      border-bottom: 1px solid color('light-primary-light-gray-200');

      &:last-child {
        border-bottom: unset;
      }

      td {
        padding: 14px 24px;

        .archived {
          width: 80px;
          background-color: color('light-primary-light-gray-100');
          border-radius: 16px;
          color: color('light-primary-light-gray-700');
          padding: 2px 8px;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;

          .circle {
            width: 6px;
            height: 6px;
            border-radius: 1000px;
            background: color('light-primary-gray-500');
            display: inline-block;
            margin-right: 7px;
          }
        }

        .deleted {
          width: 74px;
          background: color('error-50');
          border-radius: 16px;
          color: color('error-700');
          padding: 2px 8px;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;

          .circle {
            width: 6px;
            height: 6px;
            border-radius: 1000px;
            background: color('error-500');
            display: inline-block;
            margin-right: 7px;
          }
        }

        .works {
          width: 67px;
          background: color('light-success-50');
          border-radius: 16px;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: color('light-success-700');
          padding: 2px 8px;
          display: flex;
          align-items: center;

          .circle {
            width: 6px;
            height: 6px;
            border-radius: 1000px;
            background: color('light-success-500');
            display: inline-block;
            margin-right: 7px;
          }
        }

      }

      .userInfoColumn {
        display: flex;
        align-items: center;

        .image {
          width: 40px;
          height: 40px;
          margin-right: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .userCredentials {
          display: flex;
          flex-direction: column;

          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: color('dark-primary-gray-900');
          }

          .email {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: color('light-primary-gray-500');
          }
        }
      }

      .digestNumberColumn {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: color('light-primary-gray-500');
      }

      .dateColumn {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: color('light-primary-gray-500');
      }

      .sourcesColumn {
        display: flex;
        align-items: center;

        .sourceNumber {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: color('dark-primary-gray-900');
          display: inline-block;
          margin-right: 12px;
        }

        .imageContainer {
          display: flex;
          align-items: center;
          position: relative;

          .image {
            width: 40px;
            height: 40px;
            margin-right: -15px;
            border-radius: 200px;
            background: color('light-primary-light-gray-100');
            border: 2px solid color('white');

            img {
              width: 100%;
              height: 100%;
              border-radius: 200px;
            }

            .count {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: color('light-primary-light-gray-400');
            }
          }
        }
      }

      .iconsColumn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .tableFooter {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: space-between;
    width: 100%;
    background: color('white');
    border: 1px solid color('light-primary-light-gray-200');
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px 24px;
    @include media-max('md') {
      background: unset;
      flex-direction: column;
      border: none;
      box-shadow: unset;
      padding: unset;
    }

    .pageSizerContainer {
      max-width: 270px;
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;
      @include media-max('md') {
        max-width: unset;
        margin-top: 24px;
        margin-bottom: 16px;
        justify-content: center;
        border-bottom: 1px solid color('light-primary-light-gray-200');
        padding-bottom: 16px;
      }

      .sizerWrapper {
        max-width: 84px;
        width: 100%;
      }

      svg {
        margin-right: 8px;
      }

      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: color('light-primary-gray-500');
        @include media-max('md') {
          margin-right: 12px;
        }
      }
    }
  }
}

