.health-dashboard {
    padding: 1rem;
  
    .health-grid {
      display: grid;
      grid-template-columns: 1fr; 
      gap: 1rem;
  
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr); 
      }
  
      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  
    .health-card {
      width: 100%;
  
      .card-content {
        margin-bottom: 1rem;
  
        .status-row {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
  
          .status-icon {
            margin-right: 0.5rem;
          }
  
          &.text-red {
            color: #ef4444; /* Tailwind red-500 */
          }
  
          &.text-yellow {
            color: #f59e0b; /* Tailwind yellow-500 */
          }
  
          &.text-green {
            color: #10b981; /* Tailwind green-500 */
          }
  
          &.text-gray {
            color: #6b7280; /* Tailwind gray-500 */
          }
        }
  
        .status-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.25rem;
  
          strong {
            padding: 0.5rem;
          }
  
          .tag-green {
            color: #10b981;
          }
  
          .tag-red {
            color: #ef4444;
          }
        }
      }
  
      .last-updated {
        color: #6b7280; /* Tailwind gray-500 */
        font-size: 0.875rem;
      }

      .error-messages {
        margin: 8px 0;
      }
      
      .error-message {
        color: #dc3545;
        font-size: 14px;
        padding: 4px 0;
      }
    }
  }