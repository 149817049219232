.instagramCard {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #555;
    }

    p {
        margin: 5px 0;
    }

    .usersContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    .userCard {
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        width: max-content;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        .userImage {
            width: 100%;
            height: auto;
            border-radius: 50%;
            margin-bottom: 10px;
        }

        .userInfo {
            text-align: center;

            h4 {
                font-size: 16px;
                margin-bottom: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .verifiedBadge {
                color: #1da1f2;
                margin-left: 5px;
            }

            p {
                margin: 2px 0;
                font-size: 14px;
                color: #666;
            }
        }
    }
}
