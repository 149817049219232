@import "../../assets/styles/helpers";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;

  .formContainer {
    margin-top: 80px;
    min-width: 360px;
    @include media-max('sm') {
      min-width: unset;
      max-width: 360px;
      width: 100%;
    }

    .formTitle {
      font-weight: 600;
      font-size: 30px;
      line-height: 35px;
      color: color('dark-primary-gray-900');
      margin-bottom: 12px;
    }

    .formText {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: color('light-primary-gray-500');
      margin-bottom: 32px;
      margin-right: 0;
      @include media-max('xs') {
        font-size: 14px;
      }
    }

    .form {
      .formRow {
        margin-bottom: 20px;

        label {
          :first-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: color('light-primary-light-gray-700');
            margin-bottom: 6px;
          }
        }

        .inputWrapper {
          .errorMessage {
            max-width: 360px;
            width: 100%;
            color: color('error-500');
            display: block;
            margin-top: 6px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 #FEE4E2;
            @include media-max('sm') {
              font-size: 13px;
            }
          }

          input {
            &::placeholder {
              color: color('light-primary-gray-500')
            }
          }
        }

        .btnContainer {
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 64px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          height: 39px;
          color: color('white');

          button {
            background-color: color('light-primary-light-gray-600');
            color: color('white');
          }
        }

        .forgotPasswordWrapper {
          display: flex;
          justify-content: flex-end;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: color('light-primary-light-gray-700');
        }
      }
    }

    .divider {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        position: relative;
        color: color('light-primary-gray-500');

        &:before {
          background: color('light-primary-light-gray-200');
          position: absolute;
          content: '';
          width: 154px;
          height: 1px;
          top: 7px;
          left: 0;
          @media screen and (max-width: 372px) {
            width: 134px;
          }
          @media screen and (max-width: 346px) {
            width: 127px;
          }
        }

        &:after {
          background: color('light-primary-light-gray-200');
          position: absolute;
          content: '';
          width: 154px;
          height: 1px;
          top: 7px;
          right: 0;
          @media screen and (max-width: 372px) {
            width: 134px;
          }
          @media screen and (max-width: 346px) {
            width: 127px;
          }
        }
      }
    }

  }
}