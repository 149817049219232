@import "../../assets/styles/helpers";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  height: 100vh;
  @include media-max('xs') {
    justify-content: center;
  }

  .message {
    margin-top: 80px;
    max-width: 659px;
    width: 100%;
    background: color('light-primary-light-gray-100');
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 24px;
    @include media-max('xs') {
      margin-top: 0;
    }

    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: color('black');
    }
  }

  .resendContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: color('light-primary-gray-500');
      margin-right: 4px;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: color('light-primary-light-gray-700');
    }
  }
}