@import "../../assets/styles/helpers";

.container {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;

  .detailsForm {
    .userInfoInputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-max('lg') {
        flex-direction: column;

      }

      .inputWrapper {
        max-width: 308px;
        width: 100%;
        @include media-max('lg') {
          max-width: unset;
          &:first-child {
            margin-bottom: 20px;
          }
        }

        label {
          margin-bottom: 6px;
          display: inline-block;
          margin-right: 0;
        }
      }
    }

    .selectWrapper {
      label {
        margin-bottom: 6px;
        display: inline-block;
        margin-right: 0;
      }
    }

    .inputWrapper {
      label {
        margin-bottom: 6px;
        display: inline-block;
        margin-right: 0;
      }
    }

    .divider {
      margin: 20px 0;
    }


  }

  .textareaWrapper {
    margin-bottom: 12px;

    label {
      margin-bottom: 6px;
      display: inline-block;
      margin-right: 0;
    }

    .textareaText {
      display: inline-block;
      margin-top: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: color('light-primary-gray-500');
    }
  }

  .uploadPicture {
    display: flex;
    align-items: flex-start;
    @include media-max('sm') {
      flex-direction: column;
    }

    .userImage {
      max-width: 64px;
      height: 64px;
      border: 4px solid color('white');
      box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.03), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      width: 100%;
      margin-right: 20px;
      border-radius: 200px;
      @include media-max('sm') {
        margin-right: unset;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 200px;
      }
    }

    .dragAndDropWrapper {
      max-width: 556px;
      width: 100%;
    }
  }

  .buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;

    .saveBtn {
      max-width: 79px;
      width: 100%;
      height: 36px;
      border-radius: 64px;

      button {
        color: color('white');
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;

      }

    }

    .cancelBtn {
      max-width: 79px;
      width: 100%;

      border: 1px solid color('light-primary-light-gray-300');
      border-radius: 64px;
      height: 36px;
      margin-right: 12px;

      button {
        color: color('light-primary-light-gray-700');
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;

      }
    }
  }
}

.modalContent {
  width: 410px;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  background: color('white');
  border: 1px solid color('light-primary-light-gray-200');
  box-shadow: 24px 0 56px rgba(52, 64, 84, 0.03);
  border-radius: 8px;
  padding: 20px;
  @include media-max('sm') {
    width: 343px;
  }
  @include media-max('sm') {
    width: 300px;
  }

  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: color('dark-primary-gray-900');
    margin-bottom: 24px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: color('light-primary-light-gray-600');
  }

  .buttons {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 24px;

    .cancelBtnContainer {
      max-width: 189px;
      width: 100%;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-right: 12px;

      button {
        border: 1px solid #D9DDDB
      }
    }

    .removeBtnContainer {
      max-width: 189px;
      width: 100%;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }


  ul {
    max-width: 338px;
    width: 100%;
    padding-left: 20px;

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      list-style-type: initial;
      margin-bottom: 20px;
      color: color('light-primary-light-gray-600');
    }

  }

  .picContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .uploadWrapper {
    max-width: 390px;
    width: 100%;
    height: 123px;
    background: color('white');
    border: 1px solid color('light-primary-light-gray-200');
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;

  }
}

.dropContainer {
  width: 430px;
  @include media-max('sm') {
    width: 343px;
  }
  @include media-max('sm') {
    width: 300px;
  }
}
