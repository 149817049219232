@import "../../../assets/styles/helpers";

.container {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  padding: 0 116px 0 112px;
  @include media-max('lg') {
    padding: 0 40px;
  }
  @include media-max('md') {
    padding: 0 15px;
  }
  @include media-max('sm') {
    height: 72px;
    padding: 0 20px 0 16px;
  }
}