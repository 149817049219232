@import "../../../assets/styles/helpers";

.calendar {
  display: flex;
  width: 100%;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: color('light-primary-light-gray-700');

  svg {
    margin-right: 10px;
  }
}