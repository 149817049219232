.twitterCard {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    p {
        margin: 5px 0;
    }

    .userCard {
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.02);
        }

        .userImage {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
        }

        .userInfo {
            h3 {
                font-size: 16px;
                margin: 0;

                .verified {
                    color: #1da1f2;
                    font-size: 14px;
                    margin-left: 5px;
                }
            }

            p {
                margin: 2px 0;
            }
        }
    }
}
