@import "../../../assets/styles/helpers";

.container {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  overflow-x: auto;


  .table {
    width: 100%;
    border-left: 1px solid color('light-primary-light-gray-200');
    border-right: 1px solid color('light-primary-light-gray-200');
    border-collapse: collapse;
    background: color('white');
    
  }
}

