.googleCalendarCard {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;

    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 18px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 6px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}