.textCard {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #555;
    }

    p {
        margin: 5px 0;
    }

}
