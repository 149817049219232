@import "../../../assets/styles/helpers";

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .textArea {
    width: 100%;
    height: 132px;
    overflow: auto;
    outline: none;
    resize: none;
    background: color('white');
    padding: 10px 14px;
    border: 1px solid color('light-primary-light-gray-300');
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    &::placeholder {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: color('dark-primary-gray-900');
    }

    &:focus {
      border: 1px solid color('light-primary-yellow-300');
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FAE3AA;
      border-radius: 8px;
    }
  }
}