@import "../../assets/styles/helpers";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  height: 100vh;
  @include media-max('xs') {
    align-items: center;
  }

  .formContainer {
    min-width: 360px;
    margin-top: 80px;
    @include media-max('sm') {
      min-width: unset;
      max-width: 360px;
      width: 100%;
    }
    @include media-max('xs') {
      margin-top: 0;
    }

    .formTitle {
      font-weight: 600;
      font-size: 30px;
      line-height: 35px;
      color: color('dark-primary-gray-900');
      margin-bottom: 12px;
      @include media-max('xs') {
        font-size: 26px;
      }
    }

    .formText {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: color('light-primary-gray-500');
      margin-bottom: 32px;
      @include media-max('xs') {
        font-size: 14px;
      }
    }

    .form {
      .formRow {
        margin-bottom: 20px;

        label {
          :first-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: color('light-primary-light-gray-700');
            margin-bottom: 6px;
          }
        }

        .inputWrapper {
          .errorMessage {
            color: color('error-500');
            display: block;
            margin-top: 6px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 #FEE4E2;
          }

          input {
            &::placeholder {
              color: color('light-primary-gray-500')
            }
          }
        }

        .btnContainer {
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 64px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          height: 39px;

          button {
            color: color('white');
            background-color: color('light-primary-light-gray-600');
          }
        }
      }
    }

    .rememberWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: color('light-primary-gray-500');
        margin-right: 4px;
      }

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: color('light-primary-light-gray-700');
      }
    }
  }
}