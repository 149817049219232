@import "../../assets/styles/helpers";

.wrapper {
  .imageContainer {
    max-width: 40px;
    width: 100%;
    height: 40px;
    border-radius: 200px;
    cursor: pointer;
    border: 4px solid #F5F7F6;

    img {
      width: 100%;
      height: 100%;
      border-radius: 200px;
    }
  }

  @include media-max('sm') {
    display: none;
  }
}
