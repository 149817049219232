@import "../../../assets/styles/helpers";


.Buttons {
  .BtnContainer {
    max-width: 390px;
    height: 40px;

    &:first-child {
      margin-bottom: 12px;
    }

    &:nth-child(2) {
      border: 1px solid color('light-primary-light-gray-300');
      border-radius: 64px;
    }

    button {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: color('black');
    }
  }
}


.container {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
}

.active {
  border: 2px dashed color('light-primary-light-gray-200');
  border-radius: 8px;
}

.upload {
  .drop {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid color('light-primary-light-gray-200');
    border-radius: 8px;
    margin: 0;
    width: 100%;
    height: 118px;
    cursor: pointer;

    .dropWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        display: flex;
        align-items: center;
        background: color('light-primary-light-gray-100');
        border: 6px solid color('light-primary-gray-50');
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 28px;
        margin-bottom: 24px;
      }

      .dropMessage {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .dropHyperlink {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          display: inline-block;
          color: color('light-primary-light-gray-700');
          margin-right: 4px;
        }

        .dropText {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: color('light-primary-light-gray-600');
        }

      }

      .extension {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: color('light-primary-light-gray-600');
      }
    }

    .fileInput {
      display: none;
    }
  }
}


.modalContent {
  width: 410px;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  background: color('white');
  border: 1px solid color('light-primary-light-gray-200');
  box-shadow: 24px 0 56px rgba(52, 64, 84, 0.03);
  border-radius: 8px;
  padding: 20px;
  @include media-max('sm') {
    width: 343px;
  }
  @include media-max('sm') {
    width: 300px;
  }

  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: color('dark-primary-gray-900');
    margin-bottom: 24px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: color('light-primary-light-gray-600');
  }

  .buttons {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 24px;

    .cancelBtnContainer {
      max-width: 189px;
      width: 100%;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-right: 12px;

      button {
        border: 1px solid #D9DDDB
      }
    }

    .removeBtnContainer {
      max-width: 189px;
      width: 100%;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }


  ul {
    max-width: 338px;
    width: 100%;
    padding-left: 20px;

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      list-style-type: initial;
      margin-bottom: 20px;
      color: color('light-primary-light-gray-600');
    }

  }

  .picContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .uploadWrapper {
    max-width: 390px;
    width: 100%;
    height: 123px;
    background: color('white');
    border: 1px solid color('light-primary-light-gray-200');
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;

  }
}


.dropContainer {
  width: 430px;
  @include media-max('sm') {
    width: 343px;
  }
  @include media-max('sm') {
    width: 300px;
  }
}
