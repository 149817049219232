@import "../../assets/styles/helpers";

.container {
  max-width: 640px;
  width: 100%;
  height: 120px;
  border: 1px solid color('light-primary-light-gray-300');
  border-radius: 8px;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  padding: 16px 24px;
  margin-bottom: 20px;

  .socialAccount {
    display: flex;
    align-items: center;

    .picture {
      width: 32px;
      height: 32px;
      background: color('light-primary-light-gray-300');
      border-radius: 200px;
      margin-right: 12px;
    }

    .socialMediaName {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: color('black');
    }
  }

  .connectionWrapper {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .text {
      max-width: 293px;
      width: 100%;
      display: flex;
      align-items: center;

      .mainText {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        margin-right: 12px;
        color: color('light-primary-light-gray-400');
      }

      .subtext {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        max-width: 135px;
        width: 100%;
        padding: 4px 8px;
        background: color('light-primary-gray-50');
        border-radius: 6px;
        color: color('dark-primary-gray-900');
      }
    }

    .instructionBtn {
      max-width: 143px;
      width: 100%;
      height: 32px;
      border-radius: 8px;

      button {
        color: color('light-primary-light-gray-700');
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        border-radius: 8px;
        background-color: color('light-primary-light-gray-100')
      }
    }

    .btnContainer {
      max-width: 106px;
      width: 100%;
      height: 32px;
      border-radius: 8px;

      button {
        color: color('light-primary-light-gray-700');
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        border-radius: 8px;
        background-color: color('light-primary-light-gray-100')
      }
    }
  }

}