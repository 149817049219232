@import "../../assets/styles/helpers";

.container {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  padding-top: 48px;
  padding-bottom: 96px;

  .userWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-max('sm') {
      flex-direction: column;
      align-items: flex-start;

    }

    .userInfo {
      display: flex;
      align-items: center;
      @include media-max('xl') {
        margin-bottom: 25px;
      }
      @include media-max('sm') {
        flex-direction: column;
        align-items: flex-start;
      }

      .imageContainer {
        max-width: 160px;
        width: 100%;
        height: 160px;
        border: 4px solid color('white');
        box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.03), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        border-radius: 200px;
        margin-right: 32px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 200px;
        }

        @include media-max('sm') {
          width: 96px;
          height: 96px;
          margin-right: 0;
          margin-bottom: 24px;
        }
      }

      .userCredentials {
        .name {
          font-weight: 500;
          font-size: 30px;
          line-height: 35px;
          color: color('dark-primary-gray-900');
          margin-bottom: 4px;
          @include media-max('sm') {
            font-size: 24px;
            line-height: 28px;
          }
        }

        .handle {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: color('light-primary-gray-500');
        }
      }
    }

    .userActions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .secondDivider {
        width: 1px;
        height: 24px;
        background: color('light-primary-light-gray-200');
        margin-left: 20px;
        margin-right: 20px;
      }

      .deleteBtn {
        width: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;

        button {
          border: 1px solid color('error-400');

          svg {
            margin-right: 0;
          }
        }
      }


      .refreshBtn {
        margin-right: 20px;
        width: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;

        button {
          border: 1px solid color('light-primary-light-gray-300');

          svg {
            margin-right: 0;
          }
        }
      }

      .singInBtn {
        width: 157px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        margin-left: 10px;

        button {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: color('white');
          background: color('light-primary-light-gray-600');
        }
      }
    }
  }

  .divider {
    margin-bottom: 48px;
    @include media-max('sm') {
      margin-bottom: 32px;
    }
  }

  .navContainer {
    margin-top: 33px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include media-max('sm') {
        align-items: flex-start;
        //justify-content: unset;
      }

      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: color('light-primary-light-gray-600');
        padding-bottom: 11px;
        margin-right: 24px;
        position: relative;
        @include media-max('md') {
          font-size: 14px;
        }

        :last-child {
          margin-right: 0;
        }

        &:hover {
          color: color('black');

          :after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 2px;
            background-color: color('black');
          }
        }
      }

      li[class="active"] {
        font-weight: 500;
        position: relative;
        color: color('black');

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          height: 2px;
          background-color: color('black');
        }
      }
    }
  }
}

.modalContent {
  width: 410px;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  background: color('white');
  border: 1px solid color('light-primary-light-gray-200');
  box-shadow: 24px 0 56px rgba(52, 64, 84, 0.03);
  border-radius: 8px;
  padding: 20px;
  @include media-max('sm') {
    width: 343px;
  }
  @include media-max('sm') {
    width: 300px;
  }

  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: color('dark-primary-gray-900');
    margin-bottom: 24px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: color('light-primary-light-gray-600');
  }

  .buttons {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 24px;

    .cancelBtnContainer {
      max-width: 189px;
      width: 100%;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-right: 12px;

      button {
        border: 1px solid #D9DDDB
      }
    }

    .removeBtnContainer {
      max-width: 189px;
      width: 100%;
      height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }


  ul {
    max-width: 338px;
    width: 100%;
    padding-left: 20px;

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      list-style-type: initial;
      margin-bottom: 20px;
      color: color('light-primary-light-gray-600');
    }

  }

  .picContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .uploadWrapper {
    max-width: 390px;
    width: 100%;
    height: 123px;
    background: color('white');
    border: 1px solid color('light-primary-light-gray-200');
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;

  }
}

.dropContainer {
  width: 430px;
  @include media-max('sm') {
    width: 343px;
  }
  @include media-max('sm') {
    width: 300px;
  }
}
