@import "../../../assets/styles/helpers";

.container {
  .mainLabel {
    display: flex;
    align-items: center;

    .mainText {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-right: 9px;
      color: color('light-primary-light-gray-700');
    }
  }

  .subLabel {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: color('light-primary-gray-500');
  }
}