$colors: (
    'black': #000000,
    'white': #ffffff,
    'light-primary-grey': #8c8987,
    'light-primary-gray-50': #f9fbfa,
    'light-primary-gray-25': #fcfdfd,
    'light-success-50': #ecfdf3,
    'light-success-500': #12b76a,
    'light-success-6': #00b42a,
    'light-success-700': #027a48,
    'light-primary-yellow-600': #f6c035,
    'light-primary-yellow-50': #fdf0cf,
    'light-primary-yellow-100': #fceabe,
    'light-primary-yellow-300': #fbe09c,
    'light-primary-light-gray-100': #f5f7f6,
    'light-primary-light-gray-200': #eaeceb,
    'light-primary-light-gray-300': #d9dddb,
    'gray': #9fa9a4,
    'light-primary-light-gray-400': #b1b4b2,
    'light-primary-gray-500': #828584,
    'light-primary-light-gray-600': #656766,
    'light-primary-light-gray-700': #525453,
    'purple': #717bbc,
    'midnight-blue': #0911b4,
    'earth-green': #6a8f6b,
    'beige': #d2c0b2,
    'gold': #d79330,
    'olive-green': #887b56,
    'dark-primary-gray-900': #272828,
    'error-50': #fef3f2,
    'error-400': #f97066,
    'error-6': #f53f3f,
    'error-500': #f04438,
    'error-600': #d92d20,
    'error-700': #b42318,
    'buildsFailed':  #f04438,
    'buildsSuccessful': #0911b4,
    'buildsProcessing': #d79330,
    'unloggedBuild':  #828584,
);


:export {
    @each $name, $color in $colors {
        #{$name}: #{$color};
    }
}
