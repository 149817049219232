.rc-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  //width: 55%;
}


.rc-pagination-item {
  display: flex;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  max-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 12px;
  list-style: none;
  outline: 0;
  cursor: pointer;
  user-select: none;
}


.rc-pagination-item a {
  color: #828584;
}

.rc-pagination-item:hover {
  color: #656766;
  background: #F9FBFA;

}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  transition: all 0.3s;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  background: #F9FBFA;
  color: #656766;
}

.rc-pagination-item-active {
  background: #F9FBFA;
  color: #656766;
}

.rc-pagination-item-active a {
  color: #656766;
}

.rc-pagination-item-active:focus a,
.rc-pagination-item-active:hover a {
  color: #656766;
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  outline: 0;
}

.rc-pagination-jump-prev button,
.rc-pagination-jump-next button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}

.rc-pagination-jump-prev button:after,
.rc-pagination-jump-next button:after {
  display: block;
  content: '•••';
}

.rc-pagination-prev, .rc-pagination-next {
  max-width: 115px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #D9DDDB;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 1000px;

  button {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 1000px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #525453;
    display: flex;
    //align-items: center;
    justify-content: center;
    padding: 12px 16px;

    .nextSvg {
      margin-left: 12px;
    }

    .prevSvg {
      margin-right: 12px;
    }
  }

}


.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-disabled,
.rc-pagination-disabled:hover,
.rc-pagination-disabled:focus {
  cursor: not-allowed;
}


.paginationText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525453;
}

.mobile-pagination-wrapper {
  display: none;
}

@media only screen and (max-width: 767px) {
  .paginationContainer {
    width: 100%;
  }
  .rc-pagination {
    display: none;
  }
  .prevSvg {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #D9DDDB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }

  .nextSvg {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #D9DDDB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }
  .btnText {
    display: none;
  }
  .mobile-pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}