@import "../../assets/styles/helpers";

.container {
  width: 100%;
  height: 80px;
  padding: 20px 116px 20px 112px;
  border-bottom: 1px solid color('light-primary-light-gray-200');
  @include media-max('lg') {
    padding: 20px 40px;
  }
  @include media-max('md') {
    padding: 20px 15px;
  }
  @include media-max('sm') {
    padding: 20px 20px 0 16px;

  }

  .menuWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-max('sm') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .contactUsWrapper {
      display: flex;
      align-items: center;
      font-family: 'Work Sans', sans-serif;
      font-style: normal;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: color('light-primary-gray-500');
        margin-right: 5px;
      }

      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: color('black');
      }
    }

    .toggleWrapper {
      display: none;
      @include media-max('sm') {
        display: block;
      }
    }

    .logoContainer {
      width: 79px;
      height: 32px;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .navigationContainer {
      @include media-max('sm') {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          list-style: none;
          color: color('light-primary-gray-500');
          cursor: pointer;
          margin-right: 32px;
          padding: 10px 18px;
          @include media-max('md') {
            margin-right: 10px;
            font-size: 14px;
          }
        }

        li[class="active"] {
          background: color('light-primary-light-gray-100');
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 64px;
          color: color('light-primary-light-gray-700');
        }

        li:hover {
          background: color('light-primary-light-gray-100');
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 64px;
          color: color('light-primary-light-gray-700');
        }
      }
    }


    .profileContainer {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;

      .toggleWrapper {
        display: none;
        @include media-max("sm") {
          display: block;
        }
      }

      @include media-max('md') {
        max-width: 190px;
      }
      @include media-max('xs') {
        max-width: 175px;
      }

      .userProfileInfo {
        display: flex;
        align-items: center;
        padding: 0 16px 13px;
        border-bottom: 1px solid color('light-primary-light-gray-100');

        .userInformationContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .userName {
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: color('light-primary-light-gray-700')
          }

          .userHandle {
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: color('light-primary-gray-500')
          }
        }

        .userAvatarContainer {
          max-width: 40px;
          width: 100%;
          height: 40px;
          position: relative;
          margin-right: 12px;

          .userOnline {
            position: absolute;
            top: 27px;
            left: 30px;
          }

          img {
            border-radius: 200px;
            width: 100%;
            height: 100%;
          }
        }
      }

      .profileMenuList {
        ul {
          text-align: left;

          li {
            padding: 10px 16px;
            list-style: none;
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: color('light-primary-light-gray-700');

            a {
              display: block;
            }

            &:hover {
              background-color: color('light-primary-gray-50');
            }

            &:nth-child(3) {
              border-bottom: 1px solid color('light-primary-light-gray-100');

            }

            &:nth-child(5) {
              border-bottom: 1px solid color('light-primary-light-gray-100');
            }
          }

          li[class="active"] {
            background-color: color('light-primary-gray-50');
          }
        }
      }
    }
  }

  .btnContainer {
    max-width: 169px;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    @include media-max('md') {
      max-width: 136px;
      width: 100%;
      font-size: 14px;
    }
  }
}

.menu {
  width: 100%;
  min-height: 240px;
  z-index: 9999999;
  position: absolute;
  top: 82px;
  left: 0;
  background: color('light-primary-gray-25');
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.03), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  .mobileMenuContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    .mobileNavigationContainer {
      width: 100%;

      ul {
        display: flex;
        flex-direction: column;

        &:first-child {
          margin-top: 10px;
        }

        li {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: color('black');
          padding: 10px 16px;

          &:last-child {
            margin-bottom: 20px;
          }
        }

        li[class="active"] {
          background: color('light-primary-gray-50');
          color: color('light-primary-light-gray-700')
        }

      }
    }

    .userInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 16px 18px;
      border-top: 2px solid color('light-primary-gray-50');

      .userMobileInfo {
        .userEmail {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: color('light-primary-gray-500');
        }
      }

      .userMobileAvatarContainer {
        max-width: 48px;
        width: 100%;
        height: 48px;
        margin-right: 12px;

        img {
          border-radius: 200px;
          width: 100%;
          height: 100%;
        }
      }

      .infoContainer {
        display: flex;
        align-items: center;
      }
    }
  }
}
