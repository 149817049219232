@import "../../assets/styles/helpers";

.container {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  padding-top: 48px;
  padding-bottom: 96px;

  .iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.refreshBtn {
  width: 157px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;

  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: color('white');
    background: color('light-primary-light-gray-600');
  }
}


  .buildWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

  }

}

