@import "../../assets/styles/helpers";

.primary {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 64px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  cursor: pointer;

  svg {
    display: inline-block;
    margin-right: 8px;
  }

  @include media-max('sm') {
    img {
      margin-right: 12px;
    }
  }

}