@import "../../../assets/styles/helpers";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(130, 133, 132, 0.5);
  backdrop-filter: blur(12px);
}

.container {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 100;
}

