.wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe {
  width: 100vw;
  margin: 0 auto;
  height: 100vh;
}