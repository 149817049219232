@import "../../../assets/styles/helpers";

.container {
  position: relative;

  .selectBtn {
    width: 100%;
    height: 40px;
    background: color("white");
    border: 1px solid color("light-primary-light-gray-300");
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    cursor: pointer;

    .btnText {
      font-size: 17px;
      font-weight: 400;
      color: color("black");

      @include media-max("md") {
        font-size: 14px;
      }
    }

    .clockSvg {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    .arrowDown {
      display: flex;
      height: 21px;
      width: 21px;
      color: #fff;
      font-size: 14px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
    }
  }

  .selectBtn.active {
    border: 1px solid color("light-primary-yellow-300");
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #fae3aa;

    .arrowDown {
      transform: rotate(-180deg);
    }

    ~ {
      .listItems {
        display: block;
      }
    }
  }

  .listItems {
    position: absolute;
    border-radius: 8px;
    width: 100%;
    max-height: 400px;
    padding: 5px 0;
    overflow-y: auto;
    background-color: color("white");
    z-index: 9999;
    top: 43px;
    border: 1px solid color("light-primary-light-gray-100");
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.03), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    display: none;

    .item.checked {
      background: color("light-primary-gray-50");

      .checkMark {
        display: block;
      }
    }

    .item {
      display: flex;
      align-items: center;
      list-style: none;
      height: 40px;
      cursor: pointer;
      transition: 0.3s;
      padding: 0 15px;
      margin: 0 !important;
      justify-content: space-between !important;
      border-radius: 8px;

      .itemText {
        font-size: 16px;
        font-weight: 400;
        color: color("black");
        @include media-max("md") {
          font-size: 14px;
        }
      }

      .checkMark {
        display: none;
      }

      &:hover {
        background-color: color("light-primary-gray-50");
      }
    }
  }
}
