@import "../../../assets/styles/helpers";

.container {
  position: relative;

  .input {
    width: 100%;
    height: 40px;
    background: color('white');
    border: 1px solid color('light-primary-light-gray-300');
    outline: none;
    border-radius: 100px;

    &::placeholder {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: color('dark-primary-gray-900');
    }

    &:focus {
      border: 1px solid color('light-primary-yellow-300');
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FAE3AA;
    }
  }

  .error {
    &:focus {
      border: 1px solid red;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FEE4E2;
    }

    border: 1px solid red;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FEE4E2;
  }

  .icon {
    position: absolute;
    left: 5px;
    top: 3px;
    padding: 9px 8px;
  }

}
